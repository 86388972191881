import * as styles from '@/styles/Contact.module.css'

import { graphql, useStaticQuery } from 'gatsby';

import Contact from '@/components/Contact';
import Flexbox from '@/components/Flexbox'
import Form from '@/components/Form'
import Layout from '@/containers/layout';
import Page from '@/components/Page'
import SEO from '../components/SEO';
import { useLocation } from '@reach/router'

const ContactPage = props => {
  const queryResult = useStaticQuery(graphql`
    query contactQuery {
        allMarkdownRemark(filter: {frontmatter: {type: { in: ["page", "general"]}}}) {
          nodes {
            frontmatter {
                title
                email
                phone
                content {
                  header
                  text
                }
              seo {
                description
                title
                previewImage {
                  childImageSharp {
                    original {
                      height
                      width
                    }
                  }
                  publicURL
                }
              }
            }
          }
        }
      }      
    `)

  const data = queryResult.allMarkdownRemark.nodes.find(el => el.frontmatter.title === 'Contact')
  const generalData = queryResult.allMarkdownRemark.nodes.find(el => el.frontmatter.title === 'General')
  console.log(generalData)
  let seoData = data.frontmatter.seo
  const { email, phone } = generalData.frontmatter;
  seoData.canonicalURL = useLocation();

  return (
    <Layout>
      <SEO data={seoData}></SEO>
      <Page paddingtop>
        <Flexbox>
          <div className={styles.text}>
            <Contact text={data.frontmatter?.content[0]?.text} email={email} phone={phone} />
            <div className={styles.mapContainer}>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2917.457857488609!2d-85.6818811844148!3d43.01074910175245!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8819ac0402e4bd01%3A0x37f81424c633358d!2sS%20%26%20C%20Plastic%20Coating!5e0!3m2!1sen!2sus!4v1655846739529!5m2!1sen!2sus" className={styles.map} loading="lazy" referrerpolicy="no-referrer-when-downgrade" />     </div>
          </div>
          <div className={styles.form}>
            <Form />
          </div>
        </Flexbox>
      </Page>
    </Layout>
  )
}

export default ContactPage;